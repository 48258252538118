<template>
    <router-link class="c-tease c-tease--preview" :to="articleLink">
        <span class="c-tease__img c-tease__img--left" v-lazy:background-image="`${ article.featured_image ? article.featured_image.sizes.full.src[0] : '' }`" :style="{ backgroundPosition: article.featured_image_position ? article.featured_image_position.replace(',', ' ') : null }">
        </span>
        <span class="c-tease__subtitle c-tease__subtitle--preview">{{ article.post_tagline }}</span>
        <h2 class="c-tease__title c-tease__title--preview">{{ article.post_title }}</h2>
    </router-link>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'ArticlePreview',
        props: {
            data: Object
        },

        computed: {
            ...mapGetters({
                page: 'page'
            }),

            article() {
                const articleType = this.data.type
                return this.data[`${articleType}_post`]
            },

            articleLink() {
                return `/post/${ this.article.post_name }`
            }
        }
    }
</script>

<style lang="scss" src="@/styles/components/_tease.scss"></style>